import { Form, SubmitHandler } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'
import { navigate } from '@redwoodjs/router'
import { useAuth } from 'src/auth'
import { Button } from 'src/components/catalyst/button'
import { Text } from 'src/components/catalyst/text'
import Input from 'src/components/catalyst/rw/Input'
import { useForm } from 'react-hook-form'
import TeamMembersCell from 'src/components/TeamMembersCell'
import { useState } from 'react'

const ADD_TEAM_MEMBER_MUTATION = gql`
  mutation AddTeamMember($input: AddTeamMemberInput!) {
    addTeamMember(input: $input) {
      id
      email
    }
  }
`

export default function OnboardingSeats() {
  const formMethods = useForm()
  const { currentUser } = useAuth()
  const [isAdding, setIsAdding] = useState(false)
  const [isNavigating, setIsNavigating] = useState(false)

  const [addTeamMember] = useMutation(ADD_TEAM_MEMBER_MUTATION, {
    update: (cache, { data: { addTeamMember } }) => {
      cache.modify({
        fields: {
          organizationUsers: (existingUsers, { toReference }) => {
            const newUserRef = toReference(addTeamMember)
            return [...existingUsers, newUserRef]
          },
        },
      })
    },
    onCompleted: () => {
      toast.success('User Invited to Organization')
      formMethods.reset()
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const handleNext = () => {
    setIsNavigating(true)
    navigate('/onboardingDisclaimer')
  }

  type FormFields = {
    memberEmail: string
  }

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    if (!data.memberEmail || !data.memberEmail.includes('@')) {
      toast.error('Please enter a valid email address')
      return
    }

    setIsAdding(true)
    try {
      const userObject = {
        email: data.memberEmail.toLowerCase(),
        organizationId: currentUser.organizationId,
        adminId: currentUser.id,
        roleId: 3,
      }
      await addTeamMember({ variables: { input: userObject } })
    } catch (error) {
      toast.error('Failed to add team member:', error)
    } finally {
      setIsAdding(false)
    }
  }

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center h-[100vh] ">
        <div className="w-lg-form-width flex font-roboto flex-col border-2 px-16 pt-4 mt-20 items-center border-border rounded-xl">
          <div className="h-32">
            <img
              src="/silo-logo.svg"
              alt="silo logo"
              className="h-full w-full"
            />
          </div>
          <div className="text-center text-lg flex flex-col gap-2 px-8 pb-6">
            <Text>
              Invite new team members to join your organization. Invited members
              will receive an email from registration@silogeo.com with
              instructions to set up their accounts. Remind them to check their
              spam folders.
            </Text>
          </div>
          <Form
            formMethods={formMethods}
            className="flex flex-col gap-2 w-full items-center"
            onSubmit={onSubmit}
          >
            <div className="flex flex-col w-full items-center">
              <div className="flex flex-col w-full justify-end">
                <Input
                  name="memberEmail"
                  label="Member Email"
                  placeholderText="Enter email address"
                  className="w-full"
                  validation={{
                    required: 'Email is required',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Invalid email format',
                    },
                  }}
                  errorClassName="border-2 border-red-500"
                />
                <Button
                  type="submit"
                  className="w-full text-sm mt-7 "
                  color="accent"
                  disabled={isAdding}
                >
                  {isAdding ? 'Adding...' : 'Add Member'}
                </Button>
              </div>
            </div>
          </Form>
          <div className="w-full pb-8 flex justify-center ">
            <Button
              color="primary"
              onClick={handleNext}
              className="w-full"
              disabled={isNavigating}
            >
              {isNavigating ? 'Processing...' : 'Next'}
            </Button>
          </div>
        </div>
        <div className="rw-form-wrapper w-[90%] sm:w-[90%] md:w-[90%] lg:max-w-[40%] overflow-y-auto mt-6 lg:h-1/2">
          <TeamMembersCell
            organizationId={currentUser.organizationId as number}
            allowedRoles={null} // null to disable delete feature from this page
            handleDelete={null} // null to disable delete feature from this page
          />
        </div>
      </div>
    </>
  )
}
