import { Layer, LayerType, LayerScope, ServiceType } from './LayerTypes'

export const baseLayers: Layer[] = [
  {
    type: LayerType.layer,
    id: 'satellite',
    displayName: 'Satellite',
    scope: LayerScope.BASE,
    source: {
      service: ServiceType.Mapbox,
      displayName: 'Mapbox',
      layerUrl: `mapbox://styles/mapbox/satellite-v9`,
    },
    active: true,
  },
  {
    type: LayerType.layer,
    id: 'satellite_street',
    displayName: 'Satellite Street',
    scope: LayerScope.BASE,
    source: {
      service: ServiceType.Mapbox,
      displayName: 'Mapbox',
      layerUrl: `mapbox://styles/mapbox/satellite-streets-v12`,
    },
    active: false,
  },
  {
    type: LayerType.layer,
    id: 'street',
    displayName: 'Street',
    scope: LayerScope.BASE,
    source: {
      service: ServiceType.Mapbox,
      displayName: 'Mapbox',
      layerUrl: `mapbox://styles/mapbox/streets-v12`,
    },
    active: false,
  },
  // stock mapbox base layers can be found here: https://docs.mapbox.com/api/maps/styles/
]
