import React from 'react'
import { useRouteName } from '@redwoodjs/router'
import { navigationItems } from './NavigationItems'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
// import OnlineStatusIndicator from 'src/components/OnlineStatusIndicator/OnlineStatusIndicator'

const Navigation = ({ currentComponent, onItemClick }) => {
  const routeName = useRouteName()
  const flyoutConfig = useSelector((state: RootState) => state.map.flyoutConfig)

  const isActiveComponent = (itemName: string) => {
    return (
      itemName === currentComponent || itemName === flyoutConfig.parentComponent
    )
  }

  // Temporarily disabled icons
  const activeItems = navigationItems.filter(item =>
    item.name !== 'CircleStack' && item.name !== 'OfflineMaps'
  )

  return (
    <ul
      className={`absolute flex flex-col ${
        routeName === 'map'
          ? 'top-[72px] sm:top-[56px]'
          : 'flyout-top-mobile sm:flyout-top'
      } left-0 max-w-full h-[30vh] m-3.5 space-y-2`}
    >
      {/* layer menu, layer manager, and data manager icons */}
      {/*
          TODO: when CircleStack is reenabled:
          navigationItems.slice(0, 3).map((item) => (
      */}
      <div className="bg-[#D9DCDC] rounded-lg">

        {activeItems.slice(0, 2).map((item) => (
          <li
            key={item.name}
            id={`flyout-item-${item.name.toLowerCase()}`}
            className="m-0"
          >
            <button
              onClick={() => onItemClick(item.name)}
              className={`relative flex flex-col justify-center items-center group text-gray-900
              bg-[#D9DCDC] rounded-md w-[35px] h-[35px] sm:w-[40px] sm:h-[40px]
              text-sm leading-6 font-semibold ${
                isActiveComponent(item.name)
                  ? 'before:absolute before:inset-1 before:bg-primary before:rounded-md before:z-0'
                  : ''
              }`}
            >
              <span
                className={`relative z-10 flex items-center justify-center ${
                  isActiveComponent(item.name) ? 'text-white' : ''
                }`}
              >
                <item.icon aria-hidden="true" />
              </span>
            </button>
          </li>
        ))}
      </div>

      {/* file upload/download icons */}
      {/*
          TODO: when all icons in the second group are enabled, this would change to:
          navigationItems.slice(3, 5).map((item) => (
        */}
      <div className="bg-[#D9DCDC] rounded-lg">

        {activeItems.slice(2, 4).map((item) => (
          <li
            key={item.name}
            id={`flyout-item-${item.name.toLowerCase()}`}
            className="m-0"
          >
            <button
              onClick={() => onItemClick(item.name)}
              className={`relative flex flex-col justify-center items-center group text-gray-900
              bg-[#D9DCDC] rounded-md w-[35px] h-[35px] sm:w-[40px] sm:h-[40px]
              text-sm leading-6 font-semibold ${
                isActiveComponent(item.name)
                  ? 'before:absolute before:inset-1 before:bg-primary before:rounded-md before:z-0'
                  : ''
              }`}
            >
              <span
                className={`relative z-10 flex items-center justify-center ${
                  isActiveComponent(item.name) ? 'text-white' : ''
                }`}
              >
                <item.icon aria-hidden="true" />
              </span>
            </button>
          </li>
        ))}
      </div>

      {/*
        OfflineMaps icon (temporary disabled)

      <li
        key="OfflineMaps"
        id="flyout-item-offlinemaps"
        className="m-0"
      >
        <button
          onClick={() => onItemClick('OfflineMaps')}
          className={`relative flex flex-col justify-center items-center group text-gray-900
          bg-[#D9DCDC] rounded-md w-[35px] h-[35px] sm:w-[40px] sm:h-[40px]
          text-sm leading-6 font-semibold ${
            isActiveComponent('OfflineMaps')
              ? 'before:absolute before:inset-1 before:bg-primary before:rounded-md before:z-0'
              : ''
          }`}
        >
          <span
            className={`relative z-10 flex items-center justify-center ${
              isActiveComponent('OfflineMaps') ? 'text-white' : ''
            }`}
          >
            <OfflineMapsIcon aria-hidden="true" />
            <div className="absolute -bottom-1 -right-1">
              <OnlineStatusIndicator />
            </div>
          </span>
        </button>
      </li>
      */}
    </ul>
  )
}

export default Navigation
