import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { Provider } from 'react-redux'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'
import SentryProvider from './providers/SentryProvider/SentryProvider'

import './scaffold.css'
import { AuthProvider, useAuth } from './auth'
import './index.css'
import { Toaster } from '@redwoodjs/web/dist/toast'
import store from './store'
import { MapProvider } from 'react-map-gl'
import { useEffect } from 'react'

const App = () => {
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light'
    if (savedTheme === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [])

  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <SentryProvider>
            <RedwoodApolloProvider
              useAuth={useAuth}
              graphQLClientConfig={{
                cacheConfig: {
                  typePolicies: {
                    Query: {
                      fields: {
                        organizationUsers: {
                          merge(_, incoming) {
                            return incoming
                          },
                        },
                        renderConfigsByOrganization: {
                          merge(_, incoming) {
                            return incoming
                          },
                        },
                        renderConfigsByFeatureTag: {
                          merge(_, incoming) {
                            return incoming
                          },
                        },
                        features: {
                          merge(_, incoming) {
                            return incoming
                          },
                        },
                      },
                    },
                  },
                },
              }}
            >
              <Toaster />
              <MapProvider>
                <Provider store={store}>
                  <Routes />
                </Provider>
              </MapProvider>
            </RedwoodApolloProvider>
          </SentryProvider>
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  )
}

export default App
