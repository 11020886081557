import { useState } from 'react'
import { Form } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import { Switch } from 'src/components/catalyst/switch'
import { Button } from 'src/components/catalyst/button'
import { useAuth } from 'src/auth'
import { useMutation } from '@redwoodjs/web'
import { UPDATE_USER_MUTATION } from 'src/queries/users'
import { toast } from '@redwoodjs/web/toast'

export const OnboardingDisclaimer = () => {
  const { currentUser, reauthenticate } = useAuth()
  const [updateUser] = useMutation(UPDATE_USER_MUTATION)
  const [understoodDisclaimer, setUnderstoodDisclaimer] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleAccept = async () => {
    setIsSubmitting(true)
    try {
      await updateUser({
        variables: {
          input: {
            id: currentUser.id,
            completedOnboarding: true,
          },
        },
      })

      await reauthenticate()
      // onboarding is complete, redirect to map
      navigate(routes.map())
    } catch (error) {
      toast.error(error.message)
      setIsSubmitting(false)
    }
  }

  return (
    <div className="w-full flex flex-col items-center justify-center h-[100vh]">
      <div className="rw-form-wrapper w-full lg:w-[30%] ">
        <div className="h-32">
          <img src="/silo-logo.svg" alt="silo logo" className="h-full w-full" />
        </div>
        <Form
          className="flex flex-col gap-8  items-center"
          onSubmit={handleAccept}
        >
          <div className="mt-8 bg-white border-black border-2 rounded-md max-h-[400px] overflow-auto px-4 py-8 flex flex-col gap-4">
            <h1 className="text-center font-semibold text-lg">Disclaimer</h1>
            <div>
              <p className="indent-8 mb-4 leading-6">
                The data available on this website including all maps, tables,
                numbers, graphics, and text (hereinafter collectively referred
                to as the &quot;information&quot;), is provided on an &quot;AS
                IS,&quot; &quot;AS AVAILABLE,&quot; and &quot;WITH ALL
                FAULTS&quot; basis. Neither SILO nor any of its officials and
                employees makes any warranty of any kind for this information,
                express or implied, including but not limited to any warranties
                of merchantability or fitness for a particular purpose, nor
                shall the distribution of this information constitute any
                warranty.
              </p>
              <p className="indent-8 mb-4 leading-6">
                The information on this website is collected from various
                sources and will change over time without notice. SILO and its
                officials and employees assume no responsibility or legal
                liability for the accuracy, completeness, reliability,
                timeliness, or usefulness of any information available on this
                website nor do they represent that the use of any information
                will not infringe privately owned rights. Information on this
                site is not intended to constitute advice nor is it to be used
                as a substitute for specific advice from a licensed
                professional.
              </p>
              <p className="indent-8 mb-4 leading-6">
                You should not act (or refrain from acting) based upon
                information in this site without independently verifying the
                information and, as necessary, obtaining professional advice
                regarding your particular facts and circumstances. References to
                any specific commercial product, process, service by trade name,
                trademark, or manufacturer do not constitute or imply
                endorsement, recommendation, or favoring by SILO and its
                officials and employees.
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center w-full gap-2">
            <Switch
              color="primary"
              checked={understoodDisclaimer}
              onChange={(checked) => setUnderstoodDisclaimer(checked)}
            />
            <p>I understand this disclaimer</p>
          </div>
          <div className="w-full m-0 mt-0 flex justify-center">
            <Button
              color="primary"
              type="submit"
              disabled={!understoodDisclaimer || isSubmitting}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}
