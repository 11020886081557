export const RENDER_CONFIGS_BY_ORG_QUERY = gql`
  query getRenderConfigsByOrg($input: RenderConfigsByOrganizationInput!) {
    renderConfigs: renderConfigsByOrganization(input: $input) {
      id
      organizationId
      name
      featureType
      layout
      paint
      outline
      iconUrl
      iconName
      primaryColor
      secondaryColor
      featureTags {
        id
        type
        displayName
      }
    }
  }
`

export const GET_LINE_STYLES_QUERY = gql`
  query getLineStyles($input: RenderConfigsByOrganizationInput!) {
    renderConfigs: renderConfigsByOrganization(input: $input) {
      id
      name
      layout
      paint
    }
  }
`

export const RENDER_CONFIG_FEATURE_COUNT = gql`
  query getRenderConfigFeatureCount($id: Int!) {
    renderConfigFeatureCount: renderConfigFeatureCount(id: $id) {
      featureCount
    }
  }
`

export const CREATE_RENDER_CONFIG_MUTATION = gql`
  mutation CreateRenderConfig($input: CreateRenderConfigInput!) {
    createRenderConfig(input: $input) {
      id
      featureType
      layout
      name
      description
      organizationId
      paint
      outline
      iconUrl
      iconName
      primaryColor
      secondaryColor
      featureTags {
        id
        type
        displayName
      }
    }
  }
`

export const RASTERIZE_AND_UPLOAD_ICON_MUTATION = gql`
  mutation RasterizeAndUploadIcon($svgData: String!) {
    rasterizeAndUploadIcon(svgData: $svgData)
  }
`

export const UPDATE_RENDER_CONFIG_MUTATION = gql`
  mutation UpdateRenderConfig($id: Int!, $input: UpdateRenderConfigInput!) {
    updateRenderConfig(id: $id, input: $input) {
      id
      organizationId
      name
      featureType
      description
      layout
      paint
      outline
      iconUrl
      iconName
      primaryColor
      secondaryColor
      featureTags {
        id
        type
        displayName
      }
    }
  }
`

export const DELETE_RENDER_CONFIG_MUTATION = gql`
  mutation deleteRenderConfig($id: Int!) {
    deleteRenderConfig(id: $id) {
      id
    }
  }
`

export const GET_RENDER_CONFIG_BY_ID_QUERY = gql`
  query getRenderConfig($id: Int!) {
    renderConfig(id: $id) {
      id
      organizationId
      name
      featureType
      layout
      paint
      outline
      iconUrl
      iconName
      primaryColor
      secondaryColor
      featureTags {
        id
        type
        displayName
      }
    }
  }
`

export const GET_RENDER_CONFIGS_BY_IDS_QUERY = gql`
  query getRenderConfigsByIds($ids: [Int!]!) {
    renderConfigsByIds: renderConfigsByIds(ids: $ids) {
      id
      organizationId
      name
      featureType
      layout
      paint
      iconUrl
      iconName
      outline
      featureTags {
        id
        type
        displayName
      }
    }
  }
`

export const GET_RENDER_CONFIGS_BY_FEATURE_TAG = gql`
  query getRenderConfigsByFeatureTag($input: RenderConfigsByFeatureTagInput!) {
    renderConfigs: renderConfigsByFeatureTag(input: $input) {
      id
      organizationId
      name
      featureType
      iconUrl
      iconName
      layout
      paint
      outline
      featureTags {
        id
        displayName
        type
      }
    }
  }
`
