import { useAuth } from 'src/auth'

interface FlyoutProps {
  children: React.ReactNode
  size: 'xsmall' | 'small' | 'medium' | 'large'
  isLoading?: boolean
  className?: string
}

export default function Flyout({
  children,
  size,
  isLoading = false,
  className = '',
}: FlyoutProps) {
  const { isAuthenticated } = useAuth()

  const sizeClasses = {
    xsmall: 'flyout-width-xsmall',
    small: 'flyout-width-small',
    medium: 'flyout-width-medium',
    large: 'flyout-width-large',
  }

  return (
    <div
      className={`absolute left-[50px] sm:left-14 rounded-lg bg-[#D9DCDC] ${
        isAuthenticated
          ? 'top-[72px] sm:top-[56px] flyout-height-mobile sm:flyout-height'
          : 'flyout-top-mobile sm:flyout-top flyout-height-mobile-public sm:flyout-height-public'
      } left-0 m-3.5 ${sizeClasses[size]} flyout-width-mobile sm:flyout-width ${
        !isLoading ? 'overflow-y-auto' : 'overflow-hidden'
      } ${className}`}
    >
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center overflow-hidden">
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  )
}
